/* Styles for old version of app */

@import 'bootstrap/dist/css/bootstrap.css';
@import 'font-awesome/css/font-awesome.css';

/* You can add global styles to this file, and also import other style files */
body {
    font-size: 14px;
}

.table-borderless td,
.table-borderless th {
    border: 0 !important;
}

.navbar {
    border-radius: 0 !important;
    border: 0;
}

table {
    color: #333333;
}

.text-disabled {
    color: grey;
}

.text-orange {
    color: #d8400e;
}

.text-white {
    color: #ffffff;
}

.text-red {
    color: red;
    font-weight: bold;
}

.align-bottom {
    vertical-align: bottom;
}

.padding-20 {
    padding: 20px;
}

.padding-top-15 {
    padding-top: 15px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.orange-bg {
    background-color: #d8400e;
}

.col-eq-height {
    display: flex;
}

.modal-header {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}
